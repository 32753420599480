<template>
  <div class="gradient-wrapper" tabindex="0">
    <div
      class="card file-object-container"
      @dblclick="openFileObject"
      @keydown.enter="openFileObject"
    >
      <file-settings
        @deleteFileObject="deleteFileObject"
        @copyDownloadLink="onCopyDownloadLink"
      />
      <div class="card-image-wrapper">
        <file-icon
          v-show="fileObject.type === 'file'"
          :extension="getFileExtension"
        ></file-icon>
        <img
          v-show="fileObject.type === 'folder'"
          src="/folder.svg"
          :alt="imgAlt"
        />
      </div>
      <div>
        <span class="card-image-text" :title="fileObject.name">{{
          fileObject.name
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadFile } from "../../utility/index.js";
import FileIcon from "./FileIcon.vue";
import FileSettings from "./FileSettings.vue";
export default {
  name: "FileObject",
  props: {
    fileObject: {
      type: Object,
      required: true,
    },
    fileRoute: {
      type: String,
      required: true,
    },
  },
  components: {
    FileIcon,
    FileSettings,
  },
  computed: {
    getImageSource() {
      if (this.fileObject.type === "folder") {
        return "/folder.svg";
      } else {
        switch (this.getFileExtension) {
          default:
            return "/file.svg";
        }
      }
    },
    getFileExtension() {
      if (this.fileObject.type === "file") {
        if (lastDot === -1) return "";
        const fileName = this.fileObject.name;
        const lastDot = fileName.lastIndexOf(".");
        return fileName.substring(lastDot + 1);
      } else {
        return "";
      }
    },
  },
  data() {
    const imgAlt = `${this.fileObject.type} - ${this.fileObject.name}`;
    return {
      imgAlt,
    };
  },
  methods: {
    openFileObject() {
      if (this.fileObject.type === "folder") {
        this.$emit("openFolder", this.fileObject.name);
      } else {
        downloadFile({
          url: this.fileObject.downloadUrl,
          fileName: this.fileObject.name,
        });
      }
    },
    async onCopyDownloadLink() {
      try {
        await navigator.clipboard.writeText(this.fileObject.downloadUrl);
      } catch (error) {
        window.alert("There was a problem copying the link. Please try again.");
      }
    },
    deleteFileObject() {
      const location =
        this.fileObject.type === "file"
          ? this.fileObject.location
          : this.fileRoute + "/" + this.fileObject.name;
      window.confirm(
        `Are you sure you want to delete "${this.fileObject.name}"?`
      ) && this.$emit("deleteFileObject", location, this.fileObject.type);
    },
  },
};
</script>

<style lang="scss">
.card-image-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.file-object-container {
  cursor: pointer;
  flex-grow: 1;
  min-width: 7em;
  max-width: 20vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 2px 2px 3px #a6a6a6;
  transition: all 0.2s ease;
  border-style: outset;
  border: 1px solid transparent;
  img {
    display: block;
    margin: 0 auto;
    max-height: 7em;
    max-width: 100%;
    height: auto;
    width: auto;
    padding: 0.5em;
  }
  > div {
    margin: 0.5em;
  }
}
.gradient-wrapper {
  width: 10em;
  display: inline-flex;
  width: max-content;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  padding: 6px 4px;
  margin: -5px;
  transition: all 0.05s ease;
  transition-delay: 0.1s;
  &:hover, &:focus {
    transform: scale(1.05);
    transition: all 0.2s ease;
    margin: 0px;
    padding: 2px;
  }
  &:hover::before, &:focus::before {
    transform: scale(1.05);
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(0, 125, 255, 1) 0%,
      rgba(0, 75, 255, 1) 10%,
      rgba(0, 50, 255, 1) 20%,
      rgba(0, 0, 255, 1) 30%,
      rgba(50, 0, 255, 1) 40%,
      rgba(75, 25, 255, 1) 50%,
      rgba(50, 0, 255, 1) 60%,
      rgba(0, 0, 255, 1) 70%,
      rgba(0, 50, 255, 1) 80%,
      rgba(0, 75, 255, 1) 90%,
      rgba(0, 125, 255, 1) 100%
    );
    background-size: 50% 50%;
    animation: gradient-animate 1.5s linear infinite;
  }
}
</style>
