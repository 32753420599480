<template>
  <div>
    <file-pond
      name="test"
      ref="pond"
      label-idle="Drop files here or <span class='filepond--label-action'>Browse</span>"
      allow-multiple="true"
      v-on:init="handleFilePondInit"
      v-on:addfile="handleAddFile"
      v-on:removefile="handleRemoveFile"
    />
  </div>
</template>

<script>
import vueFilePond from 'vue-filepond'
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)
export default {
  name: 'UploadZone',
  components: {
    FilePond: FilePond
  },
  props: {
    files: {
      type: Array,
      required: false
    },
  },
  methods: {
    handleFilePondInit: function() {
      this.$emit('filePondInit', this.$refs.pond);
    },
    handleAddFile() {
      this.$emit('filesChanged', this.$refs.pond.getFiles())
    },
    handleRemoveFile() {
      // this.files = this.$refs.pond.getFiles()
    }
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .filepond--item {
    width: calc(33% - 0.5em);
  }
  .filepond--root {
    max-height: 40em;
    overflow: auto;
  }
  .filepond--credits {
    display: none;
  }
</style>
