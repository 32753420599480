<template>
  <button class="btn btn-primary m-auto" @click="$emit('startCreateFolder')">
    + Folder
  </button>
</template>

<script>
export default {
  name: "Upload",
};
</script>
