<template>
  <div>
    <div v-if="loading">Loading...</div>
    <div v-else>
      <b>{{totalSizeBytes}}</b> bytes in <b>{{numFiles}}</b> files uploaded so far!
    </div>
  </div>
</template>

<script>
import CONSTANTS from '../constants.json'
export default {
  name: "HomeContent",
  data() {
    return {
      loading: false,
      numFiles: 0,
      totalSizeBytes: 0
    }
  },
  mounted() {
    this.requestStats()
  },
  methods: {
    requestStats() {
      this.loading = true
      fetch(CONSTANTS.API.PUBLIC_URL, {
        method: "POST",
        body: JSON.stringify({
          action: CONSTANTS.API.PUBLIC_ACTIONS.GET_ALL_STATS
        }),
      }).then(response => {
        response.json().then(data => {
          this.loading = false
          this.numFiles = data.numFiles
          this.totalSizeBytes = data.totalSizeBytes
        })
      })
    }
  }
};
</script>
