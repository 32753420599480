<template>
  <div id="upload-modal" class="modal" tabindex="1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-dark">Upload</h5>
          <button
            type="button"
            class="btn close"
            aria-label="Close"
            v-on:click.stop="$emit('onClose')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <upload-zone
            v-on:filesChanged="onFilesChanged"
            @filePondInit="onFilePondInit"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click.stop="$emit('onSave', value)"
            :disabled="isDisabled"
          >
            {{ saveText }}
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            v-on:click.stop="$emit('onClose')"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadZone from "./UploadZone.vue";

const isFileValid = (file) => {
  // check that file has valid name
  if (!file.filename.match(/^[0-9a-zA-Z_\-. ()]+$/)) {
    return false;
  }
  return true;
};

export default {
  components: { UploadZone },
  name: "UploadModal",
  props: {
    files: {
      type: Array,
      default: null,
    }
  },
  data() {
    return {
      isDisabled: !this.files || this.files.length === 0
    }
  },
  computed: {
    saveText() {
      return this.files && this.files.length === 1 ? "Upload File" : "Upload Files";
    },
  },
  emits: ["filePondInit", "onError", "filesChanged"],
  methods: {
    onFilePondInit(filePond) {
      this.isDisabled = false
      this.$emit("filePondInit", filePond);
    },
    onFilesChanged(files) {
      const validFiles = [];
      const invalidFiles = [];
      files.forEach((file) => {
        if (isFileValid(file)) {
          validFiles.push(file);
        } else {
          invalidFiles.push(file);
        }
      });
      if (invalidFiles.length) {
        this.$emit("onError", invalidFiles);
      }
      this.$emit("filesChanged", validFiles);
    },
  },
};
</script>
<style scoped>
button:disabled {
  opacity: 0.3;
  background-color: rgb(4, 0, 54);
  border-color: black;
}
.modal {
  cursor: initial;
}
.modal-dialog {
  max-width: 80%;
  min-width: 400px;
}
.modal-content {
  max-height: 75vh;
  overflow: auto;
}
</style>