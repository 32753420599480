<template>
  <div>
    <div class="row">
      <breadcrumbs :fileRoute="fileRoute" v-on:change-route="previousRoute" />

      <div class="col-3 d-flex flex-nowrap button-container">
        <upload :fileRoute="fileRoute" @uploadedFiles="requestUserFiles" />
        <create-folder @startCreateFolder="startCreateFolder" />
      </div>
    </div>
    <div class="row mr-1 mt-4 mb-3 d-flex">
      <!-- <div class="col-8 search-container">
        <search />
      </div> -->
    </div>
    <file-display
      :isLoading="loading"
      :fileRoute="fileRoute"
      :files="savedFiles"
      :isCreatingNewFolder="isCreatingNewFolder"
      :newFolderName="newFolderName"
      @changeNewFolderName="onChangeNewFolderName"
      @saveNewFolderName="onSaveNewFolderName"
      @openFolder="openFolder"
      @deleteFileObject="deleteFileObject"
    />
  </div>
</template>

<script>
import CONSTANTS from "../constants";
// import Search from "../components/Search";
import Upload from "../components/files/upload/Upload";
import FileDisplay from "../components/files/FileDisplay";
import Breadcrumbs from "../components/Breadcrumbs";
import CreateFolder from "../components/CreateFolder";
import { getHeaders } from '../utility';

export default {
  name: "Files",
  components: {
    // Search,
    Upload,
    FileDisplay,
    Breadcrumbs,
    CreateFolder,
  },
  created() {
    // request the files for the user
    this.requestUserFiles();
  },
  data() {
    return {
      fileRoute: "",
      savedFiles: [],
      loading: false,
      error: null,
      newFolderName: "",
      isCreatingNewFolder: false,
    };
  },
  methods: {
    clearNewFolder() {
      this.newFolderName = "";
      this.isCreatingNewFolder = false;
    },
    openFolder(route) {
      const newRoute = this.fileRoute + "/" + route;
      this.fileRoute = newRoute;
      this.clearNewFolder();
      this.requestUserFiles();
    },
    onChangeNewFolderName(name) {
      this.newFolderName = name;
    },
    onSaveNewFolderName() {
      if (this.newFolderName === "") {
        this.clearNewFolder();
        return;
      }
      const createOptions = {
        method: "POST",
        headers: getHeaders(this.$auth),
        body: JSON.stringify({
          action: CONSTANTS.API.ACTIONS.CREATE_FOLDER,
          location: this.fileRoute + "/" + this.newFolderName
        }),
      };
      fetch(CONSTANTS.API.URL, createOptions).then(() => {
        this.requestUserFiles();
      });
      this.clearNewFolder();
    },
    deleteFileObject(location, type) {
      const requestOptions = {
        method: "POST",
        headers: getHeaders(this.$auth),
        body: JSON.stringify({
          action: CONSTANTS.API.ACTIONS.DELETE_FILE_OBJECT,
          location,
          type
        }),
      };
      try {
        fetch(CONSTANTS.API.URL, requestOptions).then(() => {
          this.requestUserFiles();
        });
      } catch (err) {
        this.error = err;
      }
    },
    startCreateFolder() {
      this.isCreatingNewFolder = true;
    },
    requestUserFiles() {
      this.loading = true;
      const requestOptions = {
        method: "POST",
        headers: getHeaders(this.$auth),
        body: JSON.stringify({
          action: CONSTANTS.API.ACTIONS.GET_USER_DIRECTORIES
        }),
      };
      try {
        fetch(CONSTANTS.API.URL, requestOptions)
          .then((res) => res.json())
          .then((res) => {
            this.loading = false;
            this.savedFiles = res.files;
          });
      } catch (err) {
        this.loading = false;
        this.error = err.message ? err.message : err;
      }
    },
    previousRoute(index) {
      // remove up to the given route index
      this.fileRoute = this.fileRoute
        .split("/")
        .filter((_route, i) => i <= index)
        .join("/");
      this.clearNewFolder();
      this.requestUserFiles();
    },
  },
};
</script>
<style scoped lang="scss">
.search-container {
  @media screen and (max-width: 739px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
.button-container {
  min-width: 15em;
  margin-bottom: 1em;
  @media screen and (max-width: 739px) {
    margin-top: 1em;
  }
}
</style>