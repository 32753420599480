<template>
  <button class="btn btn-primary m-auto noScale" @click="showModal">
    <font-awesome-icon icon="upload" />
    Upload
    <upload-modal
      type="file"
      v-on:onSave="onUploadFiles"
      v-on:onClose="hideModal"
      v-on:onError="onError"
      v-on:filesChanged="onFilesChanged"
      @filePondInit="onFilePondInit"
      :files="files"
    />
    <div v-if="isUploading" class="overlay">
      <span>Uploading {{uploadPercent}}%</span>
    </div>
  </button>
</template>

<script>
import Vue from "vue";
import axios from 'axios'

import CONSTANTS from "../../../constants.json";
import UploadModal from "./UploadModal.vue";
import { getHeaders } from "../../../utility";

export default {
  components: { UploadModal },
  name: "Upload",
  props: {
    fileRoute: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {
      hideOverlay: false,
      modalUploading: false,
      modalShowing: false,
      files: [],
      filePond: null,
      isUploading: false,
      uploadPercent: 0
    };
  },
  methods: {
    onError(error) {
      console.error("on error called:", error, error.message);
    },
    onFilePondInit(filePond) {
      this.filePond = filePond;
    },
    onFilesChanged(files) {
      this.files = files;
    },
    async onUploadFiles() {
      const files = this.files;
      const promises = [];
      // create a promise to get each files presigned upload url
      for (let i = 0; i < files.length; i++) {
        const location =
          (this.fileRoute ? this.fileRoute + "/" : "") +
          files[i].filename;
        const requestOptions = {
          method: "POST",
          headers: getHeaders(this.$auth),
          body: JSON.stringify({
            action: CONSTANTS.API.ACTIONS.GET_UPLOAD_URL,
            location,
            contentType: files[i].fileType,
          }),
        };
        promises.push(fetch(CONSTANTS.API.URL, requestOptions));
      }
      // request all the presigned urls
      Promise.all(promises).then(async (responses) => {
        // with the presigned urls, create a promise to upload each file
        responses.forEach((response, i) => {
          if (response.ok) {
            response.json().then(async (res) => {
              const uploadRequestOptions = {
                method: "put",
                url: res.uploadUrl,
                data: files[i].file,
                onUploadProgress: progress => {
                  this.uploadPercent = Math.round((progress.loaded / progress.total) * 100)
                }
              };
              const uploadPromises = [];
              uploadPromises.push(axios.request(uploadRequestOptions));
              this.isUploading = true
              // stop or warn user leaving if they have uploads in progress
              window.onbeforeunload = () => (this.isUploading ? true : null);
              // upload all the files and then refetch the user's files
              Promise.all(uploadPromises).then(() => {
                this.filePond.files = [];
                this.files = [];
                this.$emit("uploadedFiles");
                this.hideModal();
                this.isUploading = false
                this.uploadPercent = 0
              });
            });
          }
        });
      });
    },
    showModal() {
      Vue.nextTick(function () {
        // wait for modal to be rendered then make it visible
        document.getElementById("upload-modal").style.display = "initial";
      });
    },
    hideModal() {
      Vue.nextTick(function () {
        document.getElementById("upload-modal").style.display = "none";
      });
    },
  }
};
</script>
<style scoped lang="scss">
/* upload dialog doesn't work with changed scale */
.noScale {
  transform: unset;
}
.overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0%;
  left: 0%;
  background-color: #8080808f;
  z-index: 10000;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>