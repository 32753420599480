<template>
  <div v-if="isLoading">Loading files :)</div>
  <div v-else class="file-display">
    <temporary-folder
      v-show="isCreatingNewFolder"
      :name="newFolderName"
      :isVisible="isCreatingNewFolder"
      @changeNewFolderName="$emit('changeNewFolderName', $event)"
      @saveNewFolderName="$emit('saveNewFolderName')"
    />
    <file-object
      v-for="fileObject in getFileObjects"
      :key="fileObject.name + fileObject.type"
      :fileObject="fileObject"
      @openFolder="openFolder"
      @deleteFileObject="deleteFileObject"
      :fileRoute="fileRoute"
    >
    </file-object>
    <div
      v-show="Object.keys(getFileObjects).length === 0 && !isCreatingNewFolder"
    >
      Folder Empty
    </div>
  </div>
</template>

<script>
import CONSTANTS from "../../constants.json";
import FileObject from "./FileObject.vue";
import TemporaryFolder from "./TemporaryFolder.vue";
export default {
  name: "FileDisplay",
  props: {
    newFolderName: {
      type: String,
      required: true,
    },
    isCreatingNewFolder: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    fileRoute: {
      type: String,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
  },
  emits: [
    "open-folder",
    "deleteFileObject",
    "changeNewFolderName",
    "saveNewFolderName",
  ],
  components: {
    FileObject,
    TemporaryFolder,
  },
  methods: {
    openFolder(fileRoute) {
      this.$emit("openFolder", fileRoute);
    },
    deleteFileObject(location, type) {
      this.$emit("deleteFileObject", location, type);
    },
  },
  computed: {
    getFileObjects() {
      const self = this;
      function getRouteFiles() {
        if (self.loading) return [];
        const routeFiles = [];
        self.files.forEach((file) => {
          // files are in the form "a/b/c" where "a/b/" is the route
          // files in route "a/b/" location property starts with "a/b/" and have no more '/' characters
          const lastSlash = file.location.lastIndexOf("/");
          const fileLocation = file.location.substring(0, lastSlash);
          const fileName = file.location.substring(lastSlash + 1);
          if (
            fileLocation === self.fileRoute &&
            fileName !== CONSTANTS.PLACEHOLDER_FILE_NAME
          ) {
            routeFiles.push({ ...file, name: fileName, type: "file" });
          }
        });
        return routeFiles;
      }
      function getValidDirectories() {
        if (self.loading) return [];
        const validDirectories = [];
        self.files.forEach((object) => {
          // file route like "" or "/folder"...
          // object location like "/folder/file" or "/folder/folder/file"...
          const location = object.location.startsWith('/') ? object.location.substring(1) : object.location
          if (
            location.startsWith(self.fileRoute.substring(1)) &&
            location.lastIndexOf("/") > self.fileRoute.length
          ) {
            // a folder is created from any file with a path starting with the route and containing another '/'
            // remove the route from the folder location to get the text to the next '/'
            const remainingLocation = object.location.substring(
              self.fileRoute.length + 1
            );
            const nextPath = remainingLocation.substring(
              0,
              remainingLocation.indexOf("/")
            );
            if (
              !validDirectories.some((directory) => directory.name === nextPath)
            ) {
              validDirectories.push({ name: nextPath, type: "folder" });
            }
          }
        });
        return validDirectories;
      }
      const fileObjects = [...getRouteFiles(), ...getValidDirectories()];
      return fileObjects.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
  },
};
</script>
<style scoped>
.file-display {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 0.5em;
  row-gap: 0.5em;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  padding: 1em;
  border-radius: 5px;
}
</style>
