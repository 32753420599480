<template>
  <div id="app" class="d-flex flex-column h-100">
    <nav-bar />
    <div class="container flex-grow-1">
      <error />
      <div v-if="$auth.loading">
        Loading
      </div>
      <div v-else>
        <router-view />
      </div>
    </div>
    <footer class="bg-light text-center p-4">
      <div>
        <img src="/filesone mock.png" alt="logo" width="50" />
      </div>
      <p>
        ©{{getCopyrightYears()}} Filesone
      </p>
    </footer>
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import Error from "./components/Error";

export default {
  components: {
    NavBar,
    Error
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    getCopyrightYears() {
      const currentYear = new Date().getFullYear();
      return currentYear === 2022 ? '2022' : `2022 - ${currentYear}`;
    }
  }
};
</script>
<style scoped>
  footer {
    padding: 1em !important;
    margin-top: 1em;
  }
</style>
<style lang='scss'>
  @import './style-overrides.scss';
</style>