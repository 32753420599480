<template>
  <div >
    <font-awesome-icon :icon="['far', 'frown-open']" class="icon" />
    <div class="content">
      <p>The page you were looking for does not exist.</p>
      <router-link to="/" class="btn btn-primary w-25">Return Home</router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.icon {
  width: 6em;
  height: 6em;
  display: flex;
  margin: 5vh 50%;
  color: gray;
  box-shadow: 2px 2px 8px #808080b5;
  border-radius: 50%;
}
.content {
  display: flex;
  flex-direction: column;
  margin: 0 25%;
  & p {
    font-weight: bold;
    font-size: 1.3em;
    color: gray;
  }
}
</style>