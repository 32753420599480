<template>
  <div class="col-9">
    <ol class="breadcrumb">
      <button
        :class="{ 'breadcrumb-button': true, disabled: fileRoute === '' }"
        v-on:click="$emit('change-route', 0)"
      >
        Home
      </button>
      <li
        :class="{
          'breadcrumb-item': true,
          active: index === routes.length - 1,
        }"
        v-for="(route, index) in routes"
        :key="route.id"
      >
        <span v-if="index === routes.length - 1">
          {{ route.text }}
        </span>
        <button
          class="breadcrumb-button"
          v-else
          v-on:click="$emit('change-route', index)"
        >
          {{ route.text }}
        </button>
      </li>
    </ol>
  </div>
</template>

<script>
const convertRoutesToBreadcrumb = (routes) => {
  return routes.split("/").map((route, index) => {
    return {
      id: index,
      text: route,
    };
  });
};

export default {
  name: "Breadcrumbs",
  props: {
    fileRoute: {
      type: String,
      required: true,
    },
  },
  watch: {
    fileRoute(newRoute) {
      this.routes = convertRoutesToBreadcrumb(newRoute);
    },
  },
  data() {
    return {
      routes: convertRoutesToBreadcrumb(this.fileRoute),
    };
  },
};
</script>
<style scoped lang="scss">
.breadcrumb {
  margin-right: 5px;
  background-color: lightblue;
}
.breadcrumb-button {
  background: none;
  border: none;
  padding: 0;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  &.disabled {
    color: #999;
    text-decoration: none;
    cursor: default;
  }
}
</style>

