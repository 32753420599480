<template>
  <img class="file-icon" :src="fileIconSource" :alt="fileAltText" />
</template>

<script>
export default {
  name: 'FileIcon',
  props: {
    extension: {
      type: String,
      required: true
    }
  },
  data() {
    switch (this.extension) {
      default:
        return {
          fileIconSource: '/file2.png',
          fileAltText: `${this.extension} file`
        }
    }
  },
}
</script>