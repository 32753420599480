export const downloadFile = ({ url, fileName }) => {
  fetch(url)
    .then(res => {
      return res.blob();
    })
    .then(data => {
      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(data);
      a.download = fileName;
      a.click();
    });
};

export const getHeaders = auth => {
  return {
    "content-type": "application/json",
    ...getAuthenticatedHeaders(auth)
  };
};

const getAuthenticatedHeaders = auth => {
  return {
    Authorization: `Bearer ${auth.token}`
  };
};
