import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { Auth0Plugin } from "./auth";
import hljs from "highlight.js/lib/core";
import "highlight.js/styles/github.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLink,
  faUser,
  faPowerOff,
  faUpload,
  faSearch,
  faCircle,
  faEllipsisV,
  faTrash,
  faCopy
} from "@fortawesome/free-solid-svg-icons";
import { faFrownOpen } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import vClickOutside from "v-click-outside";

import { domain, clientId, audience } from "../auth_config.json";

Vue.config.productionTip = false;

Vue.use(hljs.vuePlugin);

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
  scope: "openid profile email read:userDirectories",
  response_type: "code token id_token",
  audience
});

Vue.use(vClickOutside);

library.add(
  faLink,
  faUser,
  faPowerOff,
  faUpload,
  faSearch,
  faCircle,
  faEllipsisV,
  faTrash,
  faCopy,
  faFrownOpen
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
