<template>
  <div class="card file-object-container">
    <div class="card-image-wrapper">
      <img src="/folder.svg" alt="new folder" />
    </div>
    <div>
      <span class="card-image-text">
        <input
          ref="folderName"
          type="text"
          :value="name"
          @keyup="onChange"
          @keydown.enter="onSave"
          @blur="onSave"
        />
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "TemporaryFolder",
  props: {
    name: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    isVisible(isVisible) {
      if (isVisible) {
        this.$refs.folderName.focus();
      }
    },
  },
  methods: {
    onChange(ev) {
      this.$emit("changeNewFolderName", ev.target.value);
    },
    onSave() {
      this.$emit("saveNewFolderName");
    },
  },
};
</script>
<style scoped lang="scss">
input {
  width: 100%;
}
.file-object-container {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  max-width: 0;
  @keyframes fadeIn {
    0% {
      min-width: 0;
      opacity: 0;
      max-width: 0;
    }
    40% {
      opacity: 0.2;
    }
    60% {
      opacity: 0.3;
    }
    80% {
      opacity: 0.6;
    }
    90% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
      max-width: 10em;
    }
  }
}
</style>
