<template>
  <div v-click-outside="closeMenu">
    <span
      class="file-settings-button-container"
      @mouseover="fileSettingsHover = true"
      @mouseleave="fileSettingsHover = false"
      @click="menuOpen = true"
      tabindex="0"
      @focus="fileSettingsHover = true"
      @blur="fileSettingsHover = false"
      @keydown.enter.stop="menuOpen = true"
    >
      <font-awesome-icon v-show="!fileSettingsHover" icon="circle" />
      <font-awesome-icon v-show="fileSettingsHover" icon="ellipsis-v" />
    </span>
    <div v-show="menuOpen" class="file-settings-menu">
      <button
        @keydown.enter.stop="confirmDelete"
        @click="confirmDelete"
        class="btn btn-danger file-settings-menu-item"
      >
        Delete <font-awesome-icon icon="trash" />
      </button>
      <button
        @keydown.enter.stop="copyDownloadLink"
        @click="copyDownloadLink"
        class="btn btn-primary file-settings-menu-item"
        title="Copy download link"
      >
        Share Link <font-awesome-icon icon="copy" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileSettings",
  emits: ["deleteFileObject", "copyDownloadLink"],
  data() {
    return {
      fileSettingsHover: false,
      menuOpen: false,
    };
  },
  methods: {
    closeMenu() {
      this.menuOpen = false;
    },
    confirmDelete() {
      this.menuOpen = false;
      this.$emit("deleteFileObject");
    },
    copyDownloadLink() {
      this.menuOpen = false;
      this.$emit("copyDownloadLink");
    },
  },
};
</script>
<style scoped lang="scss">
.file-settings-menu {
  position: absolute;
  right: 0;
  top: 35px;
  padding: 0.3em;
  background-color: #fdfdfd;
  border: 1px solid gray;
  border-radius: 7px;
  color: #444;
  row-gap: 3px;
  display: flex;
  flex-direction: column;
  button {
    border-radius: 3px;
  }
}
.file-settings-button-container {
  position: absolute;
  right: -7.5px;
  top: -7.5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0;
  font-size: 7px;
  border: 1px solid #acacac38;
  border-radius: 50%;
  transition: all 0.5s ease;
  &:hover, &:focus {
    border: 1px solid #000000;
    border-top-right-radius: 5px;
    font-size: 15px;
    width: 40px;
    height: 40px;
    right: -3px;
    top: -3px;
  }
}
</style>